define('ember-burger-menu/computed/style-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = computedStyleFor;
  function computedStyleFor(type) {
    return Ember.computed('state.{styles,open,width,position}', 'index', function () {
      var state = this.get('state');
      var args = state.getProperties(['open', 'width', 'position']);

      if (type === 'menuItem') {
        args.index = this.get('index');
      }

      return state.get('styles').generateCSSFor(type, args);
    });
  }
});