define("pix-ui/stories/pix-icon-button.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.withBackground = _exports.small = _exports.Default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixIconButton
              @ariaLabel={{ariaLabel}}
              @icon={{icon}}
              @iconPrefix={{iconPrefix}}
              @triggerAction={{triggerAction}}
              @withBackground={{withBackground}}
              @size={{size}}
              />
          
      */
      {
        "id": "xx/2PXjF",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-icon-button\",[],[[\"@ariaLabel\",\"@icon\",\"@iconPrefix\",\"@triggerAction\",\"@withBackground\",\"@size\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"ariaLabel\",\"icon\",\"iconPrefix\",\"triggerAction\",\"withBackground\",\"size\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    ariaLabel: 'Action du bouton',
    icon: 'times',
    triggerAction: function triggerAction() {
      return new Promise().resolves();
    }
  };
  var small = Template.bind({});
  _exports.small = small;
  small.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    size: 'small'
  });
  var withBackground = Template.bind({});
  _exports.withBackground = withBackground;
  withBackground.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    withBackground: true
  });
  var argTypes = {
    ariaLabel: {
      name: 'ariaLabel',
      description: 'l\'action du bouton, pour l\'accessibilité',
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    icon: {
      name: 'icon',
      description: 'Icône font-awesome',
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    iconPrefix: {
      name: 'iconPrefix',
      description: 'Prefix de l\'icône font-awesome',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['far', 'fas']
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'Fonction à appeler au clic du bouton',
      type: {
        required: true
      },
      defaultValue: (0, _addonActions.action)('triggerAction')
    },
    withBackground: {
      name: 'withBackground',
      description: 'Affichage du fond grisé',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'Size: `small`, `big`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['big', 'small']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    color: {
      name: 'color',
      description: ' ⚠️ **Propriété dépréciée** ⚠️ Color: `light-grey`, `dark-grey`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['light-grey', 'dark-grey']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'light-grey'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});