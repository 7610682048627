define("pix-ui/stories/pix-input-password.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.withPrefix = _exports.withErrorMessage = _exports.withLabelAndInformation = _exports.Default = _exports.Template = void 0;

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixInputPassword
              @ariaLabel={{ariaLabel}}
              @id={{id}}
              @label={{label}}
              @information={{information}}
              @errorMessage={{errorMessage}}
              @prefix={{prefix}}
            />
          
      */
      {
        "id": "lkV8yRZY",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-input-password\",[],[[\"@ariaLabel\",\"@id\",\"@label\",\"@information\",\"@errorMessage\",\"@prefix\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"ariaLabel\",\"id\",\"label\",\"information\",\"errorMessage\",\"prefix\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.Template = Template;
  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    id: 'firstName',
    ariaLabel: 'Mot de passe'
  };
  var withLabelAndInformation = Template.bind({});
  _exports.withLabelAndInformation = withLabelAndInformation;
  withLabelAndInformation.args = {
    id: 'password',
    label: 'Mot de passe',
    information: 'Une brève information'
  };
  var withErrorMessage = Template.bind({});
  _exports.withErrorMessage = withErrorMessage;
  withErrorMessage.args = {
    id: 'password',
    label: 'Mot de passe',
    errorMessage: 'Un message d\'erreur.'
  };
  var withPrefix = Template.bind({});
  _exports.withPrefix = withPrefix;
  withPrefix.args = {
    id: 'password',
    label: 'Mot de passe',
    prefix: 'C -'
  };
  var argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: 'Valeur de l\'input',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    label: {
      name: 'label',
      description: 'Label de l\'input. Requis si ariaLabel n\'est pas définit.',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    ariaLabel: {
      name: 'ariaLabel',
      description: 'L\'action du bouton, pour l\'accessibilité. Requis si label n\'est pas définit.',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    information: {
      name: 'information',
      description: 'Un descriptif complétant le label',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    errorMessage: {
      name: 'errorMessage',
      description: 'Affiche le message d\'erreur donné et encadre en rouge le champ',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    prefix: {
      name: 'prefix',
      description: 'Affiche un préfixe avant la zone de saisie du champ',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    }
  };
  _exports.argTypes = argTypes;
});