define("ember-burger-menu/utils/is-fastboot", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isFastboot;
  /* global self */

  function isFastboot() {
    return !self.document;
  }
});