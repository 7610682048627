define("ember-simple-auth-oidc/config", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Object.assign({
    host: "http://localhost:4200",
    clientId: "client",
    authEndpoint: null,
    tokenEndpoint: null,
    endSessionEndpoint: null,
    afterLogoutUri: null,
    userinfoEndpoint: null,
    scope: "openid",
    // expiresIn is the fallback expire time if none is given
    expiresIn: 3600 * 1000,
    refreshLeeway: 1000 * 30,
    tokenPropertyName: "access_token",
    authHeaderName: "Authorization",
    authPrefix: "Bearer",
    amountOfRetries: 3,
    retryTimeout: 3000
  }, _emberGetConfig.default["ember-simple-auth-oidc"] || {});

  _exports.default = _default;
});