define("ember-simple-auth-oidc/utils/absoluteUrl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(url) {
    var host = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "".concat(location.protocol, "//").concat(location.host);
    return /^http(s)?/.test(url) ? url : "".concat(host).concat(url);
  };

  _exports.default = _default;
});