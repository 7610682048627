define("ember-simple-auth-oidc/index", ["exports", "ember-simple-auth-oidc/config", "ember-simple-auth-oidc/utils/absoluteUrl"], function (_exports, _config, _absoluteUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.handleUnauthorized = void 0;
  var afterLogoutUri = _config.default.afterLogoutUri;

  var handleUnauthorized = function handleUnauthorized(session) {
    if (session.isAuthenticated) {
      Ember.set(session, "data.nextURL", location.href.replace(location.origin, ""));
      session.invalidate();
      var url = afterLogoutUri || "";

      if (!Ember.testing) {
        location.replace((0, _absoluteUrl.default)(url));
      }
    }
  };

  _exports.handleUnauthorized = handleUnauthorized;
  var _default = {
    handleUnauthorized: handleUnauthorized
  };
  _exports.default = _default;
});