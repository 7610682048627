define('ember-burger-menu/animations/menu-item/push', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    itemAnimation: 'push',

    menuItem: function menuItem(open, width, right, index) {
      return {
        transform: open ? '' : right ? 'translate3d(' + (index + 1) * 500 + 'px, 0, 0)' : 'translate3d(-' + (index + 1) * 500 + 'px, 0, 0)'
      };
    }
  });
});