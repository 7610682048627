define('ember-burger-menu/components/bm-outlet', ['exports', 'ember-burger-menu/templates/components/bm-outlet', 'ember-burger-menu/computed/style-for'], function (exports, _bmOutlet, _styleFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bmOutlet.default,
    classNames: ['bm-outlet'],
    attributeBindings: ['style'],
    state: null,
    style: (0, _styleFor.default)('outlet').readOnly()
  });
});