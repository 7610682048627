define("pix-ui/stories/pix-collapsible.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.multipleCollapsible = _exports.collapsible = void 0;

  var collapsible = function collapsible(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <PixCollapsible
            @title={{title}}
            @titleIcon={{titleIcon}}>
            <div>Contenu du PixCollapsible</div>
          </PixCollapsible>
          
      */
      {
        "id": "QoRZEgxo",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n    \"],[8,\"pix-collapsible\",[],[[\"@title\",\"@titleIcon\"],[[34,0],[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[2,\"Contenu du PixCollapsible\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"title\",\"titleIcon\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.collapsible = collapsible;

  var multipleCollapsible = function multipleCollapsible(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <div>
            <PixCollapsible
              @title="Titre A"
              @titleIcon={{titleIcon}}>
                <div>Contenu A</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre B"
              @titleIcon={{titleIcon}}>
                <div>Contenu B</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre C"
              @titleIcon={{titleIcon}}>
                <div>Contenu C</div>
            </PixCollapsible>
          </div>
          
      */
      {
        "id": "xD2Y4Vpg",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"pix-collapsible\",[],[[\"@title\",\"@titleIcon\"],[\"Titre A\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[12],[2,\"Contenu A\"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,\"pix-collapsible\",[],[[\"@title\",\"@titleIcon\"],[\"Titre B\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[12],[2,\"Contenu B\"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,\"pix-collapsible\",[],[[\"@title\",\"@titleIcon\"],[\"Titre C\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[12],[2,\"Contenu C\"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"titleIcon\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.multipleCollapsible = multipleCollapsible;
  var argTypes = {
    title: {
      name: 'title',
      description: 'Intitulé du contenu du PixCollapsible',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Titre du contenu à dérouler en cliquant'
    },
    titleIcon: {
      name: 'titleIcon',
      description: 'Ajoute l\'icône donnée en paramètre avant le titre du PixCollapsible',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'user'
    }
  };
  _exports.argTypes = argTypes;
});