define("pix-ui/stories/pix-textarea.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.textarea = void 0;

  var textarea = function textarea(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTextarea 
              @id={{id}}
              @value={{value}}
              @maxlength={{maxlength}}
              @label={{label}}
              @errorMessage={{errorMessage}}
            />
          
      */
      {
        "id": "EsxYJWuj",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-textarea\",[],[[\"@id\",\"@value\",\"@maxlength\",\"@label\",\"@errorMessage\"],[[34,0],[34,1],[34,2],[34,3],[34,4]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"id\",\"value\",\"maxlength\",\"label\",\"errorMessage\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.textarea = textarea;
  var argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    value: {
      name: 'value',
      description: 'Valeur du champ',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    maxlength: {
      name: 'maxlength',
      description: 'Nombre de caractères maximal à taper dans le champ',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 500
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    errorMessage: {
      name: 'errorMessage',
      description: 'Affiche une erreur en dessous du champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});