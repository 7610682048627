define('ember-burger-menu/components/bm-menu', ['exports', 'ember-burger-menu/templates/components/bm-menu', 'ember-burger-menu/computed/style-for'], function (exports, _bmMenu, _styleFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OUTLET_MENU_ANIMATIONS = undefined;
  var OUTLET_MENU_ANIMATIONS = exports.OUTLET_MENU_ANIMATIONS = ['push', 'rotate-out', 'squeeze'];

  exports.default = Ember.Component.extend({
    layout: _bmMenu.default,
    state: null,

    classNames: ['bm-menu-container'],

    itemTagName: 'div',
    dismissOnItemClick: false,

    onOpen: function onOpen() {},
    onClose: function onClose() {},


    style: (0, _styleFor.default)('menu').readOnly(),

    renderInPlace: Ember.computed('state.animation', function () {
      return OUTLET_MENU_ANIMATIONS.indexOf(this.get('state.animation')) === -1;
    }).readOnly(),

    menuItems: Ember.computed(function () {
      return Ember.A([]);
    }).readOnly(),

    onOpenChange: Ember.observer('state.open', function () {
      this[this.get('state.open') ? 'onOpen' : 'onClose']();
    })
  });
});