define("pix-ui/stories/pix-tooltip.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.unescapeHtml = _exports.bottom = _exports.right = _exports.left = _exports.isInline = _exports.isWide = _exports.isLight = _exports.Default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTooltip
              @id="tooltip-1"
              @text={{this.text}}
              @position={{this.position}}
              @isLight={{this.isLight}}
              @isInline={{this.isInline}}
              @isWide={{this.isWide}}
              @unescapeHtml={{this.unescapeHtml}}
            >
              <PixButton aria-describedby="tooltip-1">
                {{this.label}}
              </PixButton>
            </PixTooltip>
          
      */
      {
        "id": "UYajZPrI",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-tooltip\",[],[[\"@id\",\"@text\",\"@position\",\"@isLight\",\"@isInline\",\"@isWide\",\"@unescapeHtml\"],[\"tooltip-1\",[32,0,[\"text\"]],[32,0,[\"position\"]],[32,0,[\"isLight\"]],[32,0,[\"isInline\"]],[32,0,[\"isWide\"]],[32,0,[\"unescapeHtml\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"pix-button\",[[24,\"aria-describedby\",\"tooltip-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,0,[\"label\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    text: 'Hello World',
    label: 'À survoler pour voir la tooltip'
  };
  var isLight = Template.bind({});
  _exports.isLight = isLight;
  isLight.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isLight: true
  });
  var isWide = Template.bind({});
  _exports.isWide = isWide;
  isWide.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas molestie mauris vel viverra.',
    isWide: true
  });
  var isInline = Template.bind({});
  _exports.isInline = isInline;
  isInline.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Je suis une trèèèèèèèès longue information',
    isInline: true
  });
  var left = Template.bind({});
  _exports.left = left;
  left.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît à gauche',
    position: 'left',
    isInline: true
  });
  var right = Template.bind({});
  _exports.right = right;
  right.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît à droite',
    position: 'right',
    isInline: true
  });
  var bottom = Template.bind({});
  _exports.bottom = bottom;
  bottom.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Mon infobulle apparaît en bas',
    position: 'bottom'
  });
  var unescapeHtml = Template.bind({});
  _exports.unescapeHtml = unescapeHtml;
  unescapeHtml.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    text: 'Hello <b style="color: red;">W</b>orld',
    label: 'J\'affiche du html',
    unescapeHtml: true
  });
  var argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant permettant de référencer le déclencheur via aria-describedby',
      type: {
        name: 'string',
        required: true
      }
    },
    text: {
      name: 'text',
      defaultValue: 'Tooltiptop',
      description: 'Texte à afficher',
      type: {
        name: 'string',
        required: false
      }
    },
    position: {
      name: 'position',
      description: 'Position de la tooltip',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'top'
        }
      },
      control: {
        type: 'select',
        options: ['top', 'top-left', 'top-right', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left']
      }
    },
    isLight: {
      name: 'isLight',
      description: 'Affichage en mode clair',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isInline: {
      name: 'isInline',
      description: 'Affichage en une seule ligne',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isWide: {
      name: 'isWide',
      description: 'Affichage large',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    unescapeHtml: {
      name: 'unescapeHtml',
      description: 'Évite d\'échapper les caractères HTML',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});