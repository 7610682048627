define("ember-simple-auth-oidc/mixins/oidc-application-route-mixin", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * This method is called after a successful authentication and continues an
     * intercepted transition if a URL is stored in `nextURL` in the
     * localstorage. Otherwise call the parent/super to invoke the normal
     * behavior of the `sessionAuthenticated` method.
     *
     * @method sessionAuthenticated
     * @public
     */
    sessionAuthenticated: function sessionAuthenticated() {
      var nextURL = this.session.data.nextURL;
      this.session.set("data.nextURL", undefined);

      if (nextURL) {
        this.replaceWith(nextURL);
      } else {
        this._super();
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      /**
       * Overwriting the standard behavior of the sessionInvalidated event,
       * which is redirecting to the rootURL of the app. Since the OIDC addon
       * also triggers a redirect in some cases and this could lead to conflicts
       * we disable the ember-simple-auth behavior.
       * If you wish to redirect after invalidating the session, please handle
       * this by overwriting this event in your application route or at the
       * exact location where the session is invalidated.
       */
    }
  });

  _exports.default = _default;
});