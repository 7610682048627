define("pix-ui/stories/pix-radio-button.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.defaultChecked = _exports.disabledChecked = _exports.isDisabled = _exports.Default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixRadioButton
              @label={{label}}
              @value={{value}}
              @isDisabled={{isDisabled}}
            />
          
      */
      {
        "id": "OOhcFLl2",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-radio-button\",[],[[\"@label\",\"@value\",\"@isDisabled\"],[[34,0],[34,1],[34,2]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"label\",\"value\",\"isDisabled\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    label: 'Poivron'
  };
  var isDisabled = Template.bind({});
  _exports.isDisabled = isDisabled;
  isDisabled.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isDisabled: true
  });

  var checked = function checked(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixRadioButton
              @label={{label}}
              @isDisabled={{isDisabled}}
              checked
            />
          
      */
      {
        "id": "FxAEAcv8",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-radio-button\",[[24,\"checked\",\"\"]],[[\"@label\",\"@isDisabled\"],[[34,0],[34,1]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"label\",\"isDisabled\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  var disabledChecked = checked.bind({});
  _exports.disabledChecked = disabledChecked;
  disabledChecked.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    isDisabled: true
  });
  var defaultChecked = checked.bind({});
  _exports.defaultChecked = defaultChecked;
  defaultChecked.args = Default.args;
  var argTypes = {
    label: {
      name: 'label',
      description: 'Le label du bouton radio',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: 'Valeur permettant d\'identifier l\'option sélectionnée',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    isDisabled: {
      name: 'isDisabled',
      description: 'Pour désactiver/activer le bouton radio',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false,
      table: {
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});