define("ember-drag-sort/components/drag-sort-list", ["exports", "ember-drag-sort/templates/components/drag-sort-list"], function (_exports, _dragSortList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // ----- Ember addons -----
  // ----- Own modules -----
  var _default = Ember.Component.extend({
    // ----- Arguments -----
    additionalArgs: undefined,
    items: undefined,
    group: undefined,
    draggingEnabled: true,
    childClass: '',
    childTagName: 'div',
    handle: null,
    sourceOnly: false,
    isHorizontal: false,
    isRtl: false,
    dragEndAction: undefined,
    dragStartAction: undefined,
    determineForeignPositionAction: undefined,
    // ----- Services -----
    dragSort: Ember.inject.service(),
    // ----- Overridden properties -----
    layout: _dragSortList.default,
    classNameBindings: [':dragSortList', 'draggingEnabled:-draggingEnabled', 'isHorizontal:-horizontal', 'isVertical:-vertical', 'isRtl:-rtl', 'isDragging:-isDragging', 'isDraggingOver:-isDraggingOver', 'isExpanded2:-isExpanded', 'isEmpty:-isEmpty', 'sourceOnly:-sourceOnlyList'],
    // ----- Static properties -----
    // ----- Aliases -----
    sourceList: Ember.computed.reads('dragSort.sourceList'),
    targetList: Ember.computed.reads('dragSort.targetList'),
    sourceIndex: Ember.computed.reads('dragSort.sourceIndex'),
    draggedItem: Ember.computed.reads('dragSort.draggedItem'),
    lastDragEnteredList: Ember.computed.reads('dragSort.lastDragEnteredList'),
    isVertical: Ember.computed.not('isHorizontal'),
    // ----- Computed properties -----
    isDragging: Ember.computed('dragSort.{isDragging,group}', 'group', function () {
      var isDragging = this.get('dragSort.isDragging');
      var group = this.get('group');
      var groupFromService = this.get('dragSort.group');
      return isDragging && group === groupFromService;
    }),
    isDraggingOver: Ember.computed('isDragging', 'items', 'targetList', function () {
      var isDragging = this.get('isDragging');
      var items = this.get('items');
      var targetList = this.get('targetList');
      return isDragging && items === targetList;
    }),
    isExpanded: Ember.computed('isDragging', 'isEmpty', 'isOnlyElementDragged', function () {
      var isDragging = this.get('isDragging');
      var isEmpty = this.get('isEmpty');
      var isOnlyElementDragged = this.get('isOnlyElementDragged');
      return isDragging && (isEmpty || isOnlyElementDragged);
    }),
    isExpanded2: Ember.computed.reads('isExpanded'),
    isEmpty: Ember.computed('items.[]', function () {
      var count = this.get('items.length');
      return !count;
    }),
    isOnlyElementDragged: Ember.computed('items.length', 'items', 'sourceList', 'sourceIndex', function () {
      var count = this.get('items.length');
      var items = this.get('items');
      var sourceList = this.get('sourceList');
      var sourceIndex = this.get('sourceIndex');
      return count === 1 && items === sourceList && !sourceIndex;
    }),
    // ----- Overridden methods -----
    dragEnter: function dragEnter(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging')) return; // Ignore irrelevant groups

      var group = this.get('group');
      var activeGroup = this.get('dragSort.group');
      if (group !== activeGroup) return;
      event.stopPropagation(); // Ignore duplicate events (explanation: https://github.com/lolmaus/jquery.dragbetter#what-this-is-all-about )

      var items = this.get('items');
      var lastDragEnteredList = this.get('lastDragEnteredList');
      if (items === lastDragEnteredList) return;
      this.dragEntering(event);

      if (this.get('determineForeignPositionAction')) {
        this.forceDraggingOver();
      }
    },
    dragOver: function dragOver(event) {
      // This event is only used for placing the dragged element into the end of a horizontal list
      if (this.get('isVertical')) {
        return;
      } // Ignore irrelevant drags


      if (!this.get('dragSort.isDragging') || this.get('determineForeignPositionAction')) return;
      var group = this.get('group');
      var activeGroup = this.get('dragSort.group');
      if (group !== activeGroup) return;
      event.stopPropagation();
      this.isDraggingOverHorizontal(event);
    },
    // ----- Custom methods -----
    dragEntering: function dragEntering(event) {
      var group = this.get('group');
      var items = this.get('items');
      var dragSort = this.get('dragSort');
      var isHorizontal = this.get('isHorizontal');
      var targetArgs = this.get('additionalArgs');
      var targetIndex = 0;

      if (isHorizontal) {
        targetIndex = this.getClosestHorizontalIndex(event);
        dragSort.set('isDraggingUp', false);
      }

      dragSort.dragEntering({
        group: group,
        items: items,
        isHorizontal: isHorizontal,
        targetArgs: targetArgs,
        targetIndex: targetIndex
      });
    },
    getClosestHorizontalIndex: function getClosestHorizontalIndex(event) {
      // Calculate which item is closest and make that the target
      var itemsNodeList = this.get('element').querySelectorAll('.dragSortItem');
      var draggableItems = Ember.A(Array.prototype.slice.call(itemsNodeList));
      var positions = Ember.A(draggableItems.map(function (draggableItem) {
        return draggableItem.getBoundingClientRect();
      }));
      var rows = positions.uniqBy('top').mapBy('top').sort();
      var currentRowPosition = rows.filter(function (row) {
        return row < event.clientY;
      }).pop();
      var closestItem = positions.filterBy('top', currentRowPosition).pop();
      return closestItem ? positions.indexOf(closestItem) : 0;
    },
    forceDraggingOver: function forceDraggingOver() {
      var determineForeignPositionAction = this.get('determineForeignPositionAction');
      var group = this.get('group');
      var items = this.get('items');
      var itemsLength = Ember.get(items, 'length');
      var draggedItem = this.get('draggedItem');
      var sourceList = this.get('sourceList');
      var dragSort = this.get('dragSort');
      var isDraggingUp = true;
      var index = items === sourceList ? items.indexOf(draggedItem) + 1 : determineForeignPositionAction({
        draggedItem: draggedItem,
        items: items
      });

      if (index >= itemsLength) {
        index = itemsLength - 1;
        isDraggingUp = false;
      }

      dragSort.draggingOver({
        group: group,
        index: index,
        items: items,
        isDraggingUp: isDraggingUp
      });
    },
    isDraggingOverHorizontal: function isDraggingOverHorizontal(event) {
      var dragSort = this.get('dragSort');
      var group = this.get('group');
      var items = this.get('items');
      var index = this.getClosestHorizontalIndex(event);
      var isDraggingUp = false;
      dragSort.draggingOver({
        group: group,
        index: index,
        items: items,
        isDraggingUp: isDraggingUp
      });
    },
    // ----- Observers -----
    setIsExpanded2: Ember.observer('isExpanded', function () {
      var _this = this;

      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      Ember.run.next(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) return;

        _this.set('isExpanded2', _this.get('isExpanded'));
      });
    })
  });

  _exports.default = _default;
});