define("pix-ui/stories/pix-progress-gauge.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.whiteProgressGauge = _exports.Default = void 0;

  var Default = function Default(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixProgressGauge
              @value={{value}}
              @color={{color}}
              @isArrowLeft={{isArrowLeft}}
              @subtitle={{subtitle}}
              @tooltipText={{tooltipText}}
            />
          
      */
      {
        "id": "+9n3pzbk",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-progress-gauge\",[],[[\"@value\",\"@color\",\"@isArrowLeft\",\"@subtitle\",\"@tooltipText\"],[[34,0],[34,1],[34,2],[34,3],[34,4]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"value\",\"color\",\"isArrowLeft\",\"subtitle\",\"tooltipText\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.Default = Default;
  Default.args = {
    tooltipText: '%'
  };

  var whiteProgressGauge = function whiteProgressGauge(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <section style="width: 100%; padding: 35px 35px 5px;background-color: lightgray">
            <PixProgressGauge
              @value={{value}}
              @color={{color}}
              @isArrowLeft={{isArrowLeft}}
              @subtitle={{subtitle}}
              @tooltipText={{tooltipText}}
            />
          </section>
          
      */
      {
        "id": "/8vegmv3",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n    \"],[10,\"section\"],[14,5,\"width: 100%; padding: 35px 35px 5px;background-color: lightgray\"],[12],[2,\"\\n      \"],[8,\"pix-progress-gauge\",[],[[\"@value\",\"@color\",\"@isArrowLeft\",\"@subtitle\",\"@tooltipText\"],[[34,0],[34,1],[34,2],[34,3],[34,4]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"value\",\"color\",\"isArrowLeft\",\"subtitle\",\"tooltipText\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.whiteProgressGauge = whiteProgressGauge;
  whiteProgressGauge.args = {
    value: '50',
    tooltipText: '50%',
    color: 'white',
    isArrowLeft: true,
    subtitle: 'Avancement'
  };
  var argTypes = {
    value: {
      name: 'value',
      description: 'Valeur atteinte sur 100',
      type: {
        name: 'number',
        required: false
      },
      table: {
        defaultValue: {
          summary: null
        }
      }
    },
    color: {
      name: 'color',
      description: 'Modifie la couleur de la barre de progression. Peut prendre les valeurs `yellow` ou `white`',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'yellow'
        }
      },
      control: {
        type: 'select',
        options: ['yellow', 'white']
      }
    },
    isArrowLeft: {
      name: 'isArrowLeft',
      description: 'Modifie la position de l\'info bulle sur la gauche',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    subtitle: {
      name: 'subtitle',
      description: 'Afficher un sous-titre sous la barre de progression',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'null'
        }
      }
    },
    tooltipText: {
      name: 'tooltipText',
      description: 'Afficher un label dans l\'info bulle au dessus de la barre de progression',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'null'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});