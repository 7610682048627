define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JBMLbvIf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"stack\",\"class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[34,0]],[15,\"onclick\",[30,[36,2],[[32,0],[35,1]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,17],null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\"],[[35,16],[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"overlayClassNamesString\",\"onClickOverlay\",\"action\",\"liquid-wormhole\",\"hasOverlay\",\"if\",\"value\",\"stack\",\"constraints\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"targetModifier\",\"targetAttachment\",\"attachment\",\"tetherTarget\",\"containerClassNamesString\",\"liquid-tether\"]}",
    "moduleName": "ember-modal-dialog/templates/components/liquid-tether-dialog.hbs"
  });

  _exports.default = _default;
});