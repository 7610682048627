define("pix-ui/stories/pix-button.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argsTypes = _exports.size = _exports.shape = _exports.loader = _exports.disabled = _exports.colors = _exports.borders = _exports.Default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <section style={{this.style}}>
            <PixButton
              @triggerAction={{action triggerAction}}
              @loadingColor={{loadingColor}}
              @shape={{shape}}
              @backgroundColor={{backgroundColor}}
              @isDisabled={{isDisabled}}
              @isLoading={{isLoading}}
              @size={{size}}
              @isBorderVisible={{isBorderVisible}}
            >
              {{this.label}}
            </PixButton>
          </section>
          {{#each extraButtons as |button|}}
            <section style={{button.style}}>
              <PixButton
                @triggerAction={{action triggerAction}}
                @loadingColor={{button.loadingColor}}
                @shape={{button.shape}}
                @backgroundColor={{button.backgroundColor}}
                @isDisabled={{button.isDisabled}}
                @isLoading={{button.isLoading}}
                @size={{button.size}}
                @isBorderVisible={{button.isBorderVisible}}
              >
                {{button.label}}
              </PixButton>
            </section>
          {{/each}}
      */
      {
        "id": "vYPmlg34",
        "block": "{\"symbols\":[\"button\"],\"statements\":[[2,\"\\n    \"],[10,\"section\"],[15,5,[32,0,[\"style\"]]],[12],[2,\"\\n      \"],[8,\"pix-button\",[],[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[30,[36,1],[[32,0],[35,0]],null],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"label\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"section\"],[15,5,[32,1,[\"style\"]]],[12],[2,\"\\n        \"],[8,\"pix-button\",[],[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[30,[36,1],[[32,0],[35,0]],null],[32,1,[\"loadingColor\"]],[32,1,[\"shape\"]],[32,1,[\"backgroundColor\"]],[32,1,[\"isDisabled\"]],[32,1,[\"isLoading\"]],[32,1,[\"size\"]],[32,1,[\"isBorderVisible\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"triggerAction\",\"action\",\"loadingColor\",\"shape\",\"backgroundColor\",\"isDisabled\",\"isLoading\",\"size\",\"isBorderVisible\",\"extraButtons\",\"-track-array\",\"each\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    loadingColor: 'white',
    shape: 'squircle',
    size: 'big',
    backgroundColor: 'blue',
    label: 'Bouton',
    triggerAction: function triggerAction() {
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, 2000);
      });
    }
  };
  var borders = Template.bind({});
  _exports.borders = borders;
  borders.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec bordure sur fond clair',
    backgroundColor: 'transparent-light',
    loadingColor: 'grey',
    isBorderVisible: true,
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    })]
  });
  var colors = Template.bind({});
  _exports.colors = colors;
  colors.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec background blue (default)',
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background green',
      backgroundColor: 'green'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background yellow',
      backgroundColor: 'yellow'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background red',
      backgroundColor: 'red'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec background grey',
      backgroundColor: 'grey'
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond clair',
      backgroundColor: 'transparent-light',
      loadingColor: 'grey',
      isBorderVisible: true
    }), _objectSpread(_objectSpread({}, Default.args), {}, {
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    })]
  });
  var disabled = Template.bind({});
  _exports.disabled = disabled;
  disabled.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton désactivé',
    isDisabled: true
  });
  var loader = Template.bind({});
  _exports.loader = loader;
  loader.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton avec loader au clic',
    backgroundColor: 'yellow',
    loadingColor: 'grey',
    extraButtons: [_objectSpread(_objectSpread({}, Default.args), {}, {
      triggerAction: function triggerAction() {},
      isLoading: true
    })]
  });
  var shape = Template.bind({});
  _exports.shape = shape;
  shape.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton rounded',
    shape: 'rounded'
  });
  var size = Template.bind({});
  _exports.size = size;
  size.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    label: 'Bouton small',
    size: 'small'
  });
  var argsTypes = {
    type: {
      name: 'type',
      description: 'type du bouton',
      type: {
        required: false
      },
      control: {
        disable: true
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'button'
        }
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'fonction à appeler en cas de clic, optionnel si le bouton est de type submit',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    loadingColor: {
      name: 'loadingColor',
      description: 'couleur de chargement: `white`, `grey`',
      type: {
        name: 'string',
        required: false
      },
      options: ['white', 'grey'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'white'
        }
      }
    },
    shape: {
      name: 'shape',
      description: 'forme: `rounded`,`squircle`',
      type: {
        name: 'string',
        required: false
      },
      options: ['rounded', 'squircle'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'squircle'
        }
      }
    },
    backgroundColor: {
      name: 'backgroundColor',
      description: 'color: `blue`, `green`, `yellow`, `red`, `grey`, `transparent-light`, `transparent-dark`',
      options: ['blue', 'green', 'yellow', 'red', 'grey', 'transparent-light', 'transparent-dark'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'blue'
        }
      }
    },
    isDisabled: {
      name: 'isDisabled',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    isLoading: {
      name: 'isLoading',
      description: 'Affiche un loader. Si `@triggerAction` retourne une promesse alors le loading est géré automatiquement.',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'taille: `big`,`small`',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    isBorderVisible: {
      name: 'isBorderVisible',
      description: 'Paramètre utilisé seulement quand le `backgroundColor` est `transparent-light` ou `transparent-dark`',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    route: {
      name: 'route',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        name: 'string',
        required: false
      }
    },
    model: {
      name: 'model',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        required: false
      }
    }
  };
  _exports.argsTypes = argsTypes;
});