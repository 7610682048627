define("pix-ui/stories/pix-block.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.block = void 0;

  var block = function block(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <PixBlock @shadow={{shadow}}>
            Lorem ipsum
          </PixBlock>
        
      */
      {
        "id": "RL+Gdo0H",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n    \"],[8,\"pix-block\",[],[[\"@shadow\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Lorem ipsum\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"hasEval\":false,\"upvars\":[\"shadow\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.block = block;
  var argTypes = {
    shadow: {
      name: 'shadow',
      description: 'Ombre sur le bloc',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'light',
      control: {
        type: 'select',
        options: ['light', 'heavy']
      }
    }
  };
  _exports.argTypes = argTypes;
});