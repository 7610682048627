define("pix-ui/stories/pix-button-link.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.emberLink = _exports.htmlLink = _exports.emberLinkTemplate = _exports.htmlLinkTemplate = void 0;

  var htmlLinkTemplate = function htmlLinkTemplate(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixButtonLink 
              @href="https://pix.fr"
              target="NEW"
              @shape={{shape}}
              @backgroundColor={{backgroundColor}}
              @size={{size}}
              @isBorderVisible={{isBorderVisible}}
              @isDisabled={{isDisabled}}
            >
              Lien HTML classique
            </PixButtonLink>
          
      */
      {
        "id": "uNBKCGnZ",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-button-link\",[[24,\"target\",\"NEW\"]],[[\"@href\",\"@shape\",\"@backgroundColor\",\"@size\",\"@isBorderVisible\",\"@isDisabled\"],[\"https://pix.fr\",[34,0],[34,1],[34,2],[34,3],[34,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Lien HTML classique\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"shape\",\"backgroundColor\",\"size\",\"isBorderVisible\",\"isDisabled\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.htmlLinkTemplate = htmlLinkTemplate;

  var emberLinkTemplate = function emberLinkTemplate(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixButtonLink 
              @route=""
              @model=""
              @query={{query}}
              @shape={{shape}}
              @backgroundColor={{backgroundColor}}
              @size={{size}}
              @isBorderVisible={{isBorderVisible}}
              @isDisabled={{isDisabled}}
            >
              Lien route Ember (LinkTo)
            </PixButtonLink>
          
      */
      {
        "id": "5EAx1Vll",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-button-link\",[],[[\"@route\",\"@model\",\"@query\",\"@shape\",\"@backgroundColor\",\"@size\",\"@isBorderVisible\",\"@isDisabled\"],[\"\",\"\",[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Lien route Ember (LinkTo)\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"query\",\"shape\",\"backgroundColor\",\"size\",\"isBorderVisible\",\"isDisabled\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.emberLinkTemplate = emberLinkTemplate;
  var htmlLink = htmlLinkTemplate.bind({});
  _exports.htmlLink = htmlLink;
  var emberLink = emberLinkTemplate.bind({});
  _exports.emberLink = emberLink;
  var argTypes = {
    href: {
      name: 'href',
      description: 'Paramètre à renseigner pour utiliser lien HTML.',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    route: {
      name: 'route',
      description: 'Paramètre à renseigner pour utiliser un composant LinkTo à la place d\'un lien classique. Il prend comme valeur la route de redirection',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    model: {
      name: 'model',
      description: 'Model Ember lié à la route utilisée',
      type: {
        required: false
      }
    },
    query: {
      name: 'query',
      description: 'Paramètre facultatif du <LinkTo> Ember permettant d\'ajouter des paires de clé/valeur dans les paramètres d\'une URL',
      type: {
        required: false
      }
    },
    shape: {
      name: 'shape',
      description: 'forme: `rounded`,`squircle`',
      type: {
        name: 'string',
        required: false
      },
      options: ['rounded', 'squircle'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'squircle'
        }
      }
    },
    backgroundColor: {
      name: 'backgroundColor',
      description: 'color: `blue`, `green`, `yellow`, `red`, `grey`, `transparent-light`, `transparent-dark`',
      options: ['blue', 'green', 'yellow', 'red', 'grey', 'transparent-light', 'transparent-dark'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'blue'
        }
      }
    },
    size: {
      name: 'size',
      description: 'taille: `big`,`small`',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    isBorderVisible: {
      name: 'isBorderVisible',
      description: 'Paramètre utilisé seulement quand le `backgroundColor` est `transparent-light` ou `transparent-dark`',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    isDisabled: {
      name: 'isDisabled',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});