define('ember-burger-menu/animations/index', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getAnimation;


  var ANIMATION_PATH = 'ember-burger-menu/animations';
  var ANIMATION_ITEM_PATH = ANIMATION_PATH + '/menu-item';

  function getAnimation(animation, itemAnimation) {
    var AnimationClass = void 0;

    if (Ember.typeOf(animation) === 'class' && animation.__isAnimation__) {
      AnimationClass = animation;
    } else {
      AnimationClass = (0, _emberRequireModule.default)(ANIMATION_PATH + '/' + animation);
      (false && !(AnimationClass) && Ember.assert('The animation \'' + animation + '\' could not be found.', AnimationClass));
    }

    if (!Ember.isEmpty(itemAnimation)) {
      var MenuItemMixin = (0, _emberRequireModule.default)(ANIMATION_ITEM_PATH + '/' + itemAnimation);

      (false && !(MenuItemMixin) && Ember.assert('The item animation \'' + itemAnimation + '\' could not be found.', MenuItemMixin));


      return AnimationClass.extend(MenuItemMixin);
    }

    return AnimationClass;
  }
});