define('ember-burger-menu/animations/scale-down', ['exports', 'ember-burger-menu/animations/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    animation: 'scale-down',

    outlet: function outlet(open, width) {
      return {
        transform: open ? 'translate3d(0, 0, -' + width + 'px)' : ''
      };
    }
  });
});