define('ember-burger-menu/animations/scale-up', ['exports', 'ember-burger-menu/animations/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    animation: 'scale-up',

    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? 'translate3d(-' + width + 'px, 0, 0)' : 'translate3d(' + width + 'px, 0, 0)' : ''
      };
    },
    menu: function menu(open, width) {
      return {
        transform: open ? '' : 'translate3d(0, 0, -' + width + 'px)'
      };
    }
  });
});