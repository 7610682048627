define("pix-ui/stories/pix-stars.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.stars = void 0;

  var stars = function stars(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixStars
              @count={{count}}
              @total={{total}}
              @alt={{alt}}
              @color={{color}}
            />
          
      */
      {
        "id": "G1M+zwdj",
        "block": "{\"symbols\":[],\"statements\":[[2,\"\\n      \"],[8,\"pix-stars\",[],[[\"@count\",\"@total\",\"@alt\",\"@color\"],[[34,0],[34,1],[34,2],[34,3]]],null],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"count\",\"total\",\"alt\",\"color\"]}",
        "moduleName": "(unknown template module)"
      }),
      context: args
    };
  };

  _exports.stars = stars;
  var argTypes = {
    count: {
      name: 'count',
      description: 'Nombre total d’étoiles actives',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 2
    },
    total: {
      name: 'total',
      description: 'Nombre total d’étoiles',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 5
    },
    alt: {
      name: 'alt',
      description: 'Message alternatif pour les étoiles',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Message'
    },
    color: {
      name: 'color',
      description: 'Couleur des étoiles',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'yellow',
      control: {
        type: 'select',
        options: ['yellow', 'blue', 'grey']
      }
    }
  };
  _exports.argTypes = argTypes;
});