define("ember-simple-auth-oidc/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    singleLogout: function singleLogout() {
      var session = this.session; // InternalSession

      var authenticator = session._lookupAuthenticator(session.authenticator);

      var idToken = this.data.authenticated.id_token; // Invalidate the ember-simple-auth session

      this.invalidate(); // Trigger a single logout on the authorization server

      return authenticator.singleLogout(idToken);
    }
  });

  _exports.default = _default;
});